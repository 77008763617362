import React, { Component, useEffect, Suspense, useState, lazy } from 'react';
import {
  HashRouter as Router,
  Route,
  NavLink,
  Navigate,
  Routes,
} from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Modal } from 'antd';
import './App.scss';
const { Header, Content, Sider } = Layout;

const Article = lazy(() => import('./pages/article'));
const ArticleDetail = lazy(() => import('./pages/article/detail'));
const Client = lazy(() => import('./pages/client'));
const Doctor = lazy(() => import('./pages/doctor'));
const Drug = lazy(() => import('./pages/drug'));
const Company = lazy(() => import('./pages/company'));
const Hospital = lazy(() => import('./pages/hospital'));

function App(props) {
  const [key, setKey] = useState('article');
  const menuList = [
    {
      name: '客户管理',
      label: 'client',
      value: 'client',
    },
    {
      name: '医生管理',
      label: 'doctor',
      value: 'doctor',
    },
    {
      name: '资讯管理',
      label: 'article',
      value: 'article/list',
    },
    {
      name: '药品管理',
      label: 'drug',
      value: 'drug',
    },
    {
      name: '医药公司',
      label: 'company',
      value: 'company',
    },
    {
      name: '医院管理',
      label: 'hospital',
      value: 'hospital',
    },
  ];

  return (
    <div className="App">
      <Router>
        <Layout>
          <Header className="App-header">
            <div className="App-title">管理后台</div>
            <div className="App-name">管理员，您好</div>
          </Header>
          <Layout>
            <Sider className="App-sider">
              <Menu
                style={{ background: '#020C19', color: '#fff' }}
                theme="dark"
              >
                {menuList.map((item) => {
                  return (
                    <Menu.Item key={item.label}>
                      <NavLink to={item.value}>{item.name}</NavLink>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Sider>
            <Content className="App-content">
              <Suspense fallback={<div>loading...</div>}>
                <Routes>
                  <Route path="/" exact element={<Navigate to="/client" />} />
                  <Route path="/article">
                    <Route path="list" element={<Article />}></Route>
                    <Route path="detail" element={<ArticleDetail />}></Route>
                  </Route>
                  <Route path="/client" element={<Client />}></Route>
                  <Route path="/doctor" element={<Doctor />}></Route>
                  <Route path="/drug" element={<Drug />}></Route>
                  <Route path="/company" element={<Company />}></Route>
                  <Route path="/hospital" element={<Hospital />}></Route>
                </Routes>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
